import React, { useState } from 'react';
import ModalComponent from 'molecules/Modal';
import WelcomeBackComponent from '../Presentation/WelcomeBackComponent';
import { getTernaryValue, isMobileDevice } from 'common/utilities/utils';
import { keepOrderAnalyticFn } from 'organisms/ReorderComponent/ReorderUtils/ReorderUtils';
import WELCOME_BACK_COSTANTS from '../Constants/Constants';

const WelcomeBackContainer = props => {
  const { setIsReturningUser, isReturningUser, isLocalized } = props;
  const [isModalOpen, setIsModalOpen] = useState(true);

  const closeModel = () => {
    setIsModalOpen(false);
    setIsReturningUser(false);
    keepOrderAnalyticFn(WELCOME_BACK_COSTANTS.KEEP_IT);
  };

  const modalProps = {
    id: 'welcomeBackModal',
    role: 'dialog',
    modalTitleId: 'welcomeBackModal',
    show: true,
    classNames: `Base-modal ${getTernaryValue(
      isMobileDevice(window.innerWidth),
      'welcome-back-mobile-modal',
      'welcome-back-modal',
    )}`,
    showCloseBtn: true,
    showBackBtn: false,
    closeButtonHandler: closeModel,
    modalTitle: '',
    modalTitleRequired: false,
    // backButtonHandler: handleClose,
    closeDataTestId: 'close-modal',
    autoFocus: true,
  };

  return isModalOpen && isReturningUser && isLocalized ? (
    <ModalComponent {...modalProps}>
      <WelcomeBackComponent onCancel={closeModel} setIsModalOpen={setIsModalOpen} />
    </ModalComponent>
  ) : null;
};

export default WelcomeBackContainer;
